<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 5L5 19"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 5L19 19"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '24',
    },
    width: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: 'label-text',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },

    iconStroke() {
      return `var(--${this.color})`
    },
  },
}
</script>
